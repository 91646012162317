#terms-and-conditions-page {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

#terms-and-conditions-page > #page-title {
  font-family: SuisseBPIntl-Antique;
  font-size: 4vw;
  margin: 5vh 0;
}

#terms-and-conditions-page > #page-content {
  width: 85%;
  font-family: SuisseBPIntl-Thin;
  font-size: 1.6vw;
  text-align: justify;
}
