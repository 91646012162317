#mobile-footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100vw;
    height: 30vh;
    min-height: 253px;
    background-color: #002948;
    box-sizing: border-box;
    padding-left: 5%;
    text-align: left;
}

.mobile-footer-section {
    margin-top: 10%
}

.mobile-footer-section-title {
    color: white;
    font-family: SuisseBPIntl-Medium;
    font-size: 4.5vw;
}

.mobile-footer-text {
    color: white;
    font-family: SuisseBPIntl-light;
    font-size: 3vw;
    text-decoration: none;
}

.clickable:hover {
    cursor: pointer;
}

#mobile-footer-copyright {
    color: white;
    font-family: SuisseBPIntl-thin;
    font-size: 2vw;
    margin-top: auto;
  }