.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  min-height: 90px;
  width: 100%;
}

.header-link {
  color: white;
  font-family: SuisseBpIntl-Medium;
  font-size: 1.1em;
  text-decoration: none;
}

.header-link:hover {
  cursor: pointer;
}

#navio-header-logo {
  height: 70px;
}

#navio-header-logo:hover {
  cursor: pointer;
}

#header-actions-container {
  display: flex;
  justify-content: space-between;
  width: 55%;
}
