@media screen and (min-device-width: 769px) and (max-device-width: 1024px) {
  #landing-page {
    min-height: 1728px;
    max-height: 1728px;
  }

  #landing-page > #main-content {
    display: flex;
    flex-direction: column;
    height: 33.34%;
    min-height: 576px;
    max-height: 576px;
    width: 100%;
  }

  #tenant-info-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 33.34%;
    min-height: 576px;
    max-height: 576px;
    width: 100%;
  }

  #landing-page > #tenant-tutorial-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 33.34%;
    min-height: 576px;
    max-height: 576px;
    width: 100%;
  }

  #landing-page-catchphrase {
    margin-top: 30px;
    width: 90%;
    color: #052e56;
    font-family: SuisseBPIntl-Bold;
    text-align: start;
    font-size: 4em;
  }

  #search-bubbles-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 88%;
    height: 55px;
    margin-top: 30px;
  }

  #search-section-owner-text {
    margin-top: 20px;
    font-size: 1em;
    font-family: SuisseBPIntl-Bold;
    color: #052e56;
    align-self: center;
  }

  #landing-page-city-banner {
    width: 100%;
    height: 33%;
    object-fit: cover;
    margin-top: auto;
  }

  #landing-page-whatsapp-icon {
    width: 60px;
    height: 60px;
    position: fixed;
    bottom: 10vh;
    right: 1vw;
  }

  .landing-page-section-title {
    font-family: SuisseBpIntl-Medium;
    font-size: 3em;
    margin-top: 15px;
  }

  .navio-info-value-catchphrase {
    margin-top: 6%;
    width: 60%;
    font-size: 2.1vw;
    font-family: SuisseBpIntl-Medium;
  }

  .navio-info-value-text {
    margin-top: 35px;
    font-family: SuisseBpIntl-Light;
    font-size: 1.7vw;
    width: 70%;
  }

  .tenant-tutorial-main-text {
    font-family: SuisseBPIntl-Medium;
    font-size: 1.5em;
  }

  .tenant-tutorial-secondary-text {
    font-family: SuisseBPIntl-Thin;
    text-align: left;
    font-size: 1em;
  }

  #tenant-tutorial-more-info-text {
    margin-top: 4%;
    color: black;
    font-size: 1.5em;
    font-family: SuisseBpIntl-Thin;
  }

  .tenant-tutorial-rounded-button {
    width: 70px;
    height: 70px;
  }

  #tenant-tutorial-more-info-text-accent {
    color: black;
    font-weight: bold;
  }
}

@media screen and (min-device-width: 2080px) {
  #landing-page {
    min-height: 3510px;
    max-height: 3510px;
  }

  #landing-page-catchphrase {
    margin-top: 3.5%;
    width: 90%;
    color: #052e56;
    font-family: SuisseBPIntl-Bold;
    text-align: start;
    font-size: 8em;
  }

  #search-bubbles-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 88%;
    height: 120px;
    margin-top: 3.5%;
  }

  #landing-page > #main-content {
    display: flex;
    flex-direction: column;
    height: 33.34%;
    min-height: 1170px;
    max-height: 1170px;
    width: 100%;
  }

  #search-section-owner-text {
    margin-top: 3%;
    font-size: 2.5em;
    font-family: SuisseBPIntl-Bold;
    color: #052e56;
    align-self: center;
  }

  #landing-page-whatsapp-icon {
    width: 120px;
    height: 120px;
    position: fixed;
    bottom: 10vh;
    right: 1vw;
  }

  #tenant-info-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 33.34%;
    min-height: 1170px;
    max-height: 1170px;
    width: 100%;
  }

  .landing-page-section-title {
    font-family: SuisseBpIntl-Medium;
    font-size: 6em;
    margin-top: 2.5%;
  }

  .navio-info-value-catchphrase {
    margin-top: 6%;
    width: 60%;
    font-size: 3em;
    font-family: SuisseBpIntl-Medium;
  }

  .navio-info-value-text {
    margin-top: 6%;
    font-family: SuisseBpIntl-Light;
    font-size: 2.5em;
    width: 70%;
  }

  #landing-page > #tenant-tutorial-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 33.34%;
    min-height: 1170px;
    max-height: 1170px;
    width: 100%;
  }

  .tenant-tutorial-main-text {
    font-family: SuisseBPIntl-Medium;
    font-size: 3.5em;
  }

  .tenant-tutorial-secondary-text {
    font-family: SuisseBPIntl-Thin;
    text-align: left;
    font-size: 2.5em;
  }

  #tenant-tutorial-more-info-text {
    margin-top: 4%;
    color: black;
    font-size: 2em;
    font-family: SuisseBpIntl-Thin;
  }

  #tenant-tutorial-more-info-text-accent {
    color: black;
    font-weight: bold;
  }
}

@media screen and (min-device-width: 1025px) and (max-device-width: 1200px) {
  #landing-page {
    min-height: 2025px;
    max-height: 2025px;
  }

  #landing-page > #main-content {
    display: flex;
    flex-direction: column;
    height: 33.34%;
    min-height: 675px;
    max-height: 675px;
    width: 100%;
  }

  #tenant-info-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 33.34%;
    min-height: 675px;
    max-height: 675px;
    width: 100%;
  }

  #landing-page > #tenant-tutorial-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 33.34%;
    min-height: 675px;
    max-height: 675px;
    width: 100%;
  }

  #landing-page-catchphrase {
    margin-top: 35px;
    width: 90%;
    color: #052e56;
    font-family: SuisseBPIntl-Bold;
    text-align: start;
    font-size: 4.5em;
  }

  #search-bubbles-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 88%;
    height: 65px;
    margin-top: 35px;
  }

  #search-section-owner-text {
    margin-top: 35px;
    font-size: 1.3em;
    font-family: SuisseBPIntl-Bold;
    color: #052e56;
    align-self: center;
  }

  #landing-page-city-banner {
    width: 100%;
    height: 33%;
    object-fit: cover;
    margin-top: auto;
  }

  #landing-page-whatsapp-icon {
    width: 70px;
    height: 70px;
    position: fixed;
    bottom: 10vh;
    right: 1vw;
  }

  .landing-page-section-title {
    font-family: SuisseBpIntl-Medium;
    font-size: 3.2em;
    margin-top: 15px;
  }

  .navio-info-value-catchphrase {
    margin-top: 6%;
    width: 60%;
    font-size: 2.1vw;
    font-family: SuisseBpIntl-Medium;
  }

  .navio-info-value-text {
    margin-top: 35px;
    font-family: SuisseBpIntl-Light;
    font-size: 1.7vw;
    width: 70%;
  }

  .tenant-tutorial-main-text {
    font-family: SuisseBPIntl-Medium;
    font-size: 1.8em;
  }

  .tenant-tutorial-secondary-text {
    font-family: SuisseBPIntl-Thin;
    text-align: left;
    font-size: 1.3em;
  }

  #tenant-tutorial-more-info-text {
    margin-top: 4%;
    color: black;
    font-size: 1.8em;
    font-family: SuisseBpIntl-Thin;
  }

  .tenant-tutorial-rounded-button {
    width: 80px;
    height: 80px;
  }

  #tenant-tutorial-more-info-text-accent {
    color: black;
    font-weight: bold;
  }
}

@media screen and (min-device-width: 1201px) and (max-device-width: 1600px) {
  #landing-page {
    min-height: 2700px;
    max-height: 2700px;
  }

  #landing-page > #main-content {
    display: flex;
    flex-direction: column;
    height: 33.34%;
    min-height: 900px;
    max-height: 900px;
    width: 100%;
  }

  #tenant-info-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 33.34%;
    min-height: 900px;
    max-height: 900px;
    width: 100%;
  }

  #landing-page > #tenant-tutorial-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 33.34%;
    min-height: 900px;
    max-height: 900px;
    width: 100%;
  }

  #landing-page-catchphrase {
    margin-top: 50px;
    width: 90%;
    color: #052e56;
    font-family: SuisseBPIntl-Bold;
    text-align: start;
    font-size: 6.5em;
  }

  #search-bubbles-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 88%;
    height: 85px;
    margin-top: 50px;
  }

  #search-section-owner-text {
    margin-top: 50px;
    font-size: 1.6em;
    font-family: SuisseBPIntl-Bold;
    color: #052e56;
    align-self: center;
  }

  #landing-page-city-banner {
    width: 100%;
    height: 33%;
    object-fit: cover;
    margin-top: auto;
  }

  #landing-page-whatsapp-icon {
    width: 90px;
    height: 90px;
    position: fixed;
    bottom: 10vh;
    right: 1vw;
  }

  .landing-page-section-title {
    font-family: SuisseBpIntl-Medium;
    font-size: 4em;
    margin-top: 25px;
  }

  .navio-info-value-catchphrase {
    margin-top: 6%;
    width: 60%;
    font-size: 2.1vw;
    font-family: SuisseBpIntl-Medium;
  }

  .navio-info-value-text {
    margin-top: 35px;
    font-family: SuisseBpIntl-Light;
    font-size: 1.7vw;
    width: 70%;
  }

  .tenant-tutorial-main-text {
    font-family: SuisseBPIntl-Medium;
    font-size: 2.4em;
  }

  .tenant-tutorial-secondary-text {
    font-family: SuisseBPIntl-Thin;
    text-align: left;
    font-size: 1.8em;
  }

  #tenant-tutorial-more-info-text {
    margin-top: 4%;
    color: black;
    font-size: 2.3em;
    font-family: SuisseBpIntl-Thin;
  }

  .tenant-tutorial-rounded-button {
    width: 100px;
    height: 100px;
  }

  #tenant-tutorial-more-info-text-accent {
    color: black;
    font-weight: bold;
  }
}

@media screen and (min-device-width: 1601px) {
  #landing-page {
    min-height: 2700px;
    max-height: 2700px;
  }

  #landing-page > #main-content {
    display: flex;
    flex-direction: column;
    height: 33.34%;
    min-height: 900px;
    max-height: 900px;
    width: 100%;
  }

  #tenant-info-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 33.34%;
    min-height: 900px;
    max-height: 900px;
    width: 100%;
  }

  #landing-page > #tenant-tutorial-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 33.34%;
    min-height: 900px;
    max-height: 900px;
    width: 100%;
  }

  #landing-page-catchphrase {
    margin-top: 50px;
    width: 90%;
    color: #052e56;
    font-family: SuisseBPIntl-Bold;
    text-align: start;
    font-size: 6.5em;
  }

  #search-bubbles-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 88%;
    height: 85px;
    margin-top: 50px;
  }

  #search-section-owner-text {
    margin-top: 50px;
    font-size: 1.6em;
    font-family: SuisseBPIntl-Bold;
    color: #052e56;
    align-self: center;
  }

  #landing-page-city-banner {
    width: 100%;
    height: 33%;
    object-fit: cover;
    margin-top: auto;
  }

  #landing-page-whatsapp-icon {
    width: 90px;
    height: 90px;
    position: fixed;
    bottom: 10vh;
    right: 1vw;
  }

  .landing-page-section-title {
    font-family: SuisseBpIntl-Medium;
    font-size: 4em;
    margin-top: 25px;
  }

  .navio-info-value-catchphrase {
    margin-top: 6%;
    width: 60%;
    font-size: 2.1vw;
    font-family: SuisseBpIntl-Medium;
  }

  .navio-info-value-text {
    margin-top: 35px;
    font-family: SuisseBpIntl-Light;
    font-size: 1.7vw;
    width: 70%;
  }

  .tenant-tutorial-main-text {
    font-family: SuisseBPIntl-Medium;
    font-size: 2.4em;
  }

  .tenant-tutorial-secondary-text {
    font-family: SuisseBPIntl-Thin;
    text-align: left;
    font-size: 1.8em;
  }

  #tenant-tutorial-more-info-text {
    margin-top: 4%;
    color: black;
    font-size: 2.3em;
    font-family: SuisseBpIntl-Thin;
  }

  .tenant-tutorial-rounded-button {
    width: 100px;
    height: 100px;
  }

  #tenant-tutorial-more-info-text-accent {
    color: black;
    font-weight: bold;
  }
}

#landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 300%;
  width: 100%;
}

#landing-page > #main-content > #search-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 60%;
  width: 100%;
}

.landing-page-catchphrase-accent {
  color: #9ab3f4;
}

.info-link {
  color: #9ab3f4;
  text-decoration: underline;
}

.info-link:hover {
  cursor: pointer;
}

#tenant-info-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(114, 154, 186, 0.4);
  border-radius: 100px;
  margin-top: 2%;
  height: 80%;
  width: 90%;
  box-sizing: border-box;
  padding-top: 1.5%;
  padding-bottom: 1%;
}

.navio-info-value {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 32%;
}

.navio-info-value-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
  width: 50%;
  height: 35%;
}

.navio-info-value-icon {
  box-sizing: border-box;
  display: block;
  height: 100%;
}

  #navio-info-value-rocket-icon {
    height: 80%;
    margin-top: 4%;
  }

  #landing-page-whatsapp-icon:hover {
    cursor: pointer;
  }

  #tenant-tutorial-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(114, 154, 186, 0.4);
    border-radius: 90px;
    margin-top: 2%;
    width: 90%;
    height: 65%;
    box-sizing: border-box;
    padding-top: 1.5%;
    padding-bottom: 1%;
  }

  #tenant-tutorial {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 80%;
    height: 100%;
    padding-left: 5%;
  }

  .tenant-tutorial-point {
    display: flex;
    align-items: center;
    width: 100%;
    height: 30%;
  }

  .tenant-tutorial-point-text-container {
    display: flex;
    flex-direction: column;
    margin-left: 4%;
    align-items: flex-start;
    justify-content: space-between;
    height: 50%;
  }

#tenant-tutorial-icon {
  display: flex;
  height: 100%;
  width: 40%;
}