.mobile-landing-page-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100vh;
    min-height: 844px;
}

#mobile-landing-page-main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 754px;
    flex-grow: 1;
}

#mobile-landing-page-search-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 76%;
    width: 100%;
    box-sizing: border-box;
    padding-top: 5%;
    padding-left: 4%;
    padding-right: 4%;
}

#mobile-landing-page-catchphrase {
    color: #052e56;
    font-size: 9.5vw;
    text-align: start;
    font-family: SuisseBPIntl-Bold;
    margin-bottom: 9%;
}

#mobile-landing-page-catchphrase-accent {
    color: #9ab3f4;
}

.mobile-landing-page-bubble-padding {
    height: 3%;
}

#mobile-search-section-owner-text {
    padding-top: 6%;
    width: 70%;
    font-size: 4vw;
    color: #052e56;
    font-family: SuisseBPIntl-Bold;
    text-align: center;
}

#mobile-landing-page-city-banner {
    width: 100%;
    object-fit: cover;
    height: 24%;
}

#mobile-landing-page-tenant-info-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 80vh;
    min-height: 675.2px;
}

.mobile-landing-page-section-title {
    font-family: SuisseBpIntl-Medium;
    padding-top: 20%;
    font-size: 10vw;
}

#mobile-landing-page-carousel-container {
    padding-top: 10%;
    width: 80%;
}

#mobile-landing-page-functions-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10%;
    width: 80%;
    height: 60%;
    box-sizing: border-box;
    padding-top: 5%;
    padding-left: 4%;
    padding-right: 6%;
    background-color: #d1e3ef;
    border-radius: 50px;
}

.mobile-landing-page-navio-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 33%;
}

.mobile-landing-page-navio-step-title {
    font-family: SuisseBPIntl-Medium;
    margin-top: 3%;
    font-size: 5vw;
}

.mobile-landing-page-navio-step-desc {
    font-family: SuisseBPIntl-Light;
    margin-top: 3%;
    font-size: 4.5vw;
}

#mobile-landing-page-whatsapp-icon {
    width: 60px;
    height: 60px;
    position: fixed;
    bottom: 1%;
    right: 2%;
}

#mobile-landing-page-whatsapp-icon:hover {
    cursor: pointer;
}

#mobile-landing-page-assosiate-message {
    box-sizing: border-box;
    padding: 0 12%;
    margin-top: -20%;
    margin-bottom: 10%;
    font-family: SuisseBPIntl-Light;
    font-size: 1.2em;
}

#mobile-landing-page-assosiate-message > span {
    font-family: SuisseBPIntl-Bold;
    text-decoration: underline;
    cursor: pointer;
}