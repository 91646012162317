.mobile-popup-container {
    margin-top: 2vw;
    margin-left: 4vw;
    margin-right: 4vw;
    text-align: center;
}

.mobile-popup-icon {
    width: 40%;
}

.mobile-popup-text {
    text-align: justify;
    font-size: 0.7em;
}

.mobile-popup-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100%;
    margin-bottom: 2vw;
    margin-top: 2vw;
}

.mobile-continue-button {
    height: 3.5vw;
    width: 10vw;
    margin-left: 0.5vw;
    cursor: pointer;
    font-size: 0.1em;
}

.mobile-popup-cross {
    position: absolute;
    top: 12px;
    right: 12px;
    font-weight: bold;
    font-size: 1.2em;
    cursor: pointer;
}