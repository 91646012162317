.modal-select {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid darkgray;
    border-radius: 10px;
    height: 4.5vh;
    text-align: center;
    font-family: SuisseBPIntl-light;
}

.modal-select:hover {
    cursor: pointer;
}

.modal-select + .modal-select {
    margin-left: 2%;
}