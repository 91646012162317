#faq-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;
}

#faq-page > #page-title {
  font-family: SuisseBPIntl-Antique;
  font-size: 4vw;
  margin: 5vh 0;
}

#faq-page > .page-header {
  width: 80%;
  font-family: SuisseBPIntl-Antique;
  font-size: 2vw;
  text-align: left;
  margin-bottom: 4vh;
}

#faq-page > .page-divider {
  width: 80%;
  border: 1px solid gray;
  margin: 2vh 0;
}

#faq-page > .question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  height: 4%;
  font-family: SuisseBPIntl-Regular;
  font-size: 1.8vw;
  text-align: left;
}

#faq-page > .question:hover {
  cursor: pointer;
}

#faq-page > .answer {
  width: 80%;
  font-family: SuisseBPIntl-Thin;
  font-size: 1.3vw;
  text-align: left;
  margin: 2vh 0;
  -webkit-animation: SHOW-BOX 0.2s ease;
  -moz-animation: SHOW-BOX 0.2s ease;
  -o-animation: SHOW-BOX 0.2s ease;
  animation: SHOW-BOX 0.2s ease;
}

.faq-page-down-icon {
  height: 100%;
  width: auto;
}

.faq-page-down-icon-rotated {
  height: 100%;
  width: auto;
  transform: rotate(180deg);
}

#faq-page-last-text {
  margin-top: 4%;
  font-family: SuisseBPIntl-Thin;
  font-size: 1.8vw;
}

#faq-page-last-text > span {
  font-family: SuisseBPIntl-Thin;
  font-weight: bold;
  text-decoration: underline;
}

#faq-page-last-text > span:hover {
  cursor: pointer;
}

@-webkit-keyframes SHOW-BOX {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes SHOW-BOX {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes SHOW-BOX {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes SHOW-BOX {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
