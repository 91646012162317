#mobile-publish-property-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    min-height: 844px;
    overflow-y: scroll;
}

#mobile-publish-property-title {
    font-family: SuisseBPIntl-Antique;
    font-size: 1.5em;
    margin: 8% 0;
}

#mobile-publish-property-page-carousel-container {
    width: 80%;
}

#mobile-publish-info-text {
    box-sizing: border-box;
    padding: 0 10%;
    text-align: justify;
    font-family: SuisseBPIntl-Medium;
    font-size: 1.2em;
    margin-top: 10%;
    margin-bottom: 4%;
}
  
#mobile-publish-info-text > span {
      color: #5C3DC3;
}

#mobile-publish-property-form {
    display: flex;
    box-sizing: border-box;
    padding: 0 10%;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
  
.mobile-publish-form-input {
    border: none;
    border-bottom: 1px solid black;
    width: 100%;
}
  
.mobile-publish-form-label {
    font-family: SuisseBPIntl-Light;
    margin-top: 4%;
    margin-bottom: 2%;
    align-self: flex-start;
}

.mobile-publish-form-label > span {
    color: red;
}
  
.mobile-publish-form-input {
    margin-bottom: 2%;
    color: #0F56BF;
    height: 6%;
}
  
#mobile-publish-form-agreement {
    font-family: SuisseBPIntl-Thin;
    font-size: 0.7em;
    margin-top: 5%;
    margin-bottom: 8%;
}

#mobile-property-promo {
    font-family: SuisseBPIntl-Light;
    font-style: italic;
    font-size: 1em;
    margin-top: 8%;
    margin-bottom: 5%;
}