#footer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 400px;
  min-height: 400px;
  background-color: #002948;
}

.footer-column {
  display: flex;
  height: 100%;
  width: 20%;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
}

.footer-text {
  color: white;
  font-family: SuisseBPIntl-light;
  font-size: 1.1vw;
  margin: 2% 0px;
  text-decoration: none;
}

#footer-copyright {
  color: white;
  font-family: SuisseBPIntl-thin;
  font-size: 0.9vw;
  margin-top: auto;
}

.footer-text.clickable:hover {
  cursor: pointer;
}
