#mobile-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #112A3E;
    width: 100%;
    height: 90px;
    min-height: 90px;
    box-sizing: border-box;
    padding: 2%;
}

#mobile-navio-header-logo {
    height: 45px;
    width: auto;
}

#mobile-navio-header-hamburger-icon {
    box-sizing: border-box;
    width: 35px;
    height: 35px;
}

#mobile-header-navigation-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 45vw;
}

.mobile-header-navigation-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 11vw;
    width: 100%;
    font-family: SuisseBPIntl-Medium;
    font-size: 4vw;
}

.mobile-header-navigation-item:active {
    background-color: rgba(0,0,0,0.1);
}