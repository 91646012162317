#mobile-listing-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    overflow: auto;
    width: 100vw;
    min-height: 844px;
}

#mobile-listing-page-filter-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid gray;
    height: 5%;
    width: 100%;
    box-sizing: border-box;
    padding-left: 2%;
    padding-right: 2%;
}

#mobile-listing-page-more-filters {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid gray;
    border-radius: 100px;
    width: 24%;
    height: 70%;
    line-height: 70%;
    font-family: SuisseBpIntl-Regular;
    font-size: 2.5vw;
}

#mobile-thumbnail-list-section {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: auto;
}
  
#mobile-listing-section-headers {
    display: flex;
    margin: 2% 0%;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    font-family: SuisseBPIntl-Antique;
}
  
#mobile-total-properties-header {
    font-size: 4vw;
    padding-left: 2%;
}

#mobile-thumbnail-list {
    display: flex;
    flex-direction: column;
    padding-left: 1%;
    padding-right: 1%;
}

.mobile-listing-page-thumbnail {
    width: 100%;
    height: 80vw;
    margin-bottom: 3%;
}

#mobile-listing-page-navigation-buttons {
    display: flex;
    flex-direction: row;
    width: 55%;
    align-self: center;
    margin-bottom: 10px;
}

#mobile-listing-page-navigation-text {
    font-family: SuisseBPIntl-Medium;
    margin-bottom: 30px;
    font-size: 2vw;
}
