#mobile-property-thumbnail {
    display: flex;
    max-height: 100%;
    flex-direction: column;
    align-items: center;
    filter: drop-shadow(0px 1px 1px rgb(160, 160, 160));
    border-radius: 25px;
    background-color: white;
}
  
#mobile-property-thumbnail:hover {
    cursor: pointer;
}
  
#mobile-thumbnail-property-image {
    width: 100%;
    height: 60%;
    object-fit: cover;
    border-radius: 25px;
}
  
#mobile-property-tags {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 13%;
    width: 100%;
    font-size: 0.9vw;
}
  
#mobile-thumbnail-property-monthly-rent {
    width: 90%;
    text-align: start;
    font-family: SuisseBPIntl-Antique;
    font-size: 5.2vw;
}
  
#mobile-thumbnail-property-monthly-rent-month {
    margin-top: 0.5%;
    font-family: SuisseBPIntl-RegularItalic;
    font-size: 4.7vw;
}
  
#mobile-thumbnail-property-municipality {
    margin-top: 0.5%;
    width: 90%;
    text-align: start;
    font-family: SuisseBPIntl-Regular;
    font-size: 3.1vw;
}
  
#mobile-thumbnail-property-street {
    margin-top: 0.5%;
    width: 90%;
    text-align: start;
    font-family: SuisseBPIntl-ThinItalic;
    font-size: 3vw;
}
  
#mobile-thumbnail-property-detail {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 12%;
    width: 90%;
    font-family: SuisseBPIntl-Light;
    font-size: 3.1vw;
}
  
.mobile-thumbnail-property-detail-group {
    display: flex;
    justify-content: space-evenly;
    height: 100%;
    width: 23%;
    align-items: center;
}
  