#owner-banner {
    display: flex;
    height: 8vh;
    width: 100%;
    color: white;
    align-items: center;
    justify-content: space-between;
    padding: 0 2%;
    font-size: 1.5vw;
    font-family: SuisseBPIntl-light;
    box-sizing: border-box;
    background-color: #3C698C;
    position: fixed;
    bottom: 0;
}

.owner-banner-clickable:hover {
    cursor: pointer;
}

.owner-banner-link {
    color: white;
}