.property-hero {
  width: 100%;
  height: 55vh;
  background-color: #d1e3ef;
  border-radius: 50px;
  padding: 8%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

#property-hero-title {
  font-family: SuisseBPIntl-Medium;
  font-size: 1.9vw;
}

#property-hero-desc {
  font-family: SuisseBPIntl-Light;
  font-size: 1.37vw;
}
