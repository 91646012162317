#publish-property-page {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#publish-property-title {
  font-family: SuisseBPIntl-Antique;
  font-size: 4vw;
  margin: 4vh 0;
}

#publish-property-content-container {
  width: 80%;
  height: 75%;
  display: flex;
  justify-content: space-between;
}

.publish-property-column-container {
  width: 45%;
  height: 80%;
}

#publish-info-text {
  text-align: justify;
  font-family: SuisseBPIntl-Medium;
  font-size: 2vw;
  margin-top: 10%;
  margin-bottom: 4%;
}

#publish-info-text > span {
    color: #5C3DC3;
}

#publish-property-form {
  display: flex;
  flex-direction: column;
  height: 87%;
  align-items: center;
}

.publish-form-input {
  border: none;
  border-bottom: 1px solid black;
  width: 100%;
}

.publish-form-label {
  font-family: SuisseBPIntl-Light;
  margin-bottom: 2%;
  align-self: flex-start;
}
.publish-form-label > span {
  color: red;
}

.publish-form-input {
  margin-bottom: 2%;
  color: #0F56BF;
  height: 6%;
}

#publish-form-agreement {
  font-family: SuisseBPIntl-Thin;
  font-size: 1vw;
  margin: 5% 0;
}

#property-promo {
  font-family: SuisseBPIntl-Light;
  font-style: italic;
  font-size: 1.8vw;
  margin-top: 5%;
}