#property-thumbnail {
  display: flex;
  max-height: 100%;
  flex-direction: column;
  align-items: center;
  filter: drop-shadow(0px 1px 1px rgb(160, 160, 160));
  border-radius: 25px;
  background-color: white;
}

#property-thumbnail:hover {
  cursor: pointer;
}

#thumbnail-property-image {
  width: 100%;
  height: 55%;
  object-fit: cover;
  border-radius: 25px;
}

#property-tags {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 13%;
  width: 100%;
  font-size: 0.9vw;
}

#thumbnail-property-monthly-rent {
  height: 9%;
  max-height: 9%;
  width: 90%;
  text-align: start;
  font-family: SuisseBPIntl-Antique;
  font-size: 1.6vw;
}

#thumbnail-property-monthly-rent-month {
  font-family: SuisseBPIntl-RegularItalic;
  font-size: 1.45vw;
}

#thumbnail-property-municipality {
  height: 6%;
  max-height: 6%;
  width: 90%;
  text-align: start;
  font-family: SuisseBPIntl-Regular;
  font-size: 0.95vw;
}

#thumbnail-property-street {
  height: 5%;
  width: 90%;
  text-align: start;
  font-family: SuisseBPIntl-ThinItalic;
  font-size: 0.85vw;
}

#thumbnail-property-detail {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 12%;
  width: 90%;
  font-family: SuisseBPIntl-Light;
  font-size: 0.9vw;
}

.thumbnail-property-detail-group {
  display: flex;
  justify-content: space-evenly;
  height: 100%;
  width: 23%;
  align-items: center;
}
