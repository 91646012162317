.popup-container {
    margin-top: 2vw;
    margin-left: 4vw;
    margin-right: 4vw;
    text-align: center;
}

.popup-icon {
    width: 8vw;
}

.popup-text {
    text-align: justify;
    font-size: 1vw;
}

.popup-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 2vw;
    margin-top: 2vw;
}

.continue-button {
    height: 3.5vw;
    width: 10vw;
    margin-left: 0.5vw;
    cursor: pointer;
    font-size: 1vw;
}

.popup-cross {
    position: absolute;
    top: 0vw;
    right: 1.5vw;
    width: 1.5vw;
    font-weight: bold;
    font-size: 1.2vw;
    cursor: pointer;
}