.mobile-property-hero {
  width: 100%;
  height: 34vh;
  min-height: 254px;
  background-color: #d1e3ef;
  border-radius: 25px;
  padding: 9%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

#mobile-property-hero-title {
  font-family: SuisseBPIntl-Medium;
  font-size: 1.4em;
}

#mobile-property-hero-desc {
  font-family: SuisseBPIntl-Light;
  font-size: 1em;
}