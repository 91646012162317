#mobile-terms-and-conditions-page {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
#mobile-terms-and-conditions-page > #mobile-page-title {
    font-family: SuisseBPIntl-Antique;
    font-size: 1.5em;
    margin: 5vh 0;
}
  
#mobile-terms-and-conditions-page > #mobile-page-content {
    width: 85%;
    font-family: SuisseBPIntl-Thin;
    font-size: 1em;
    text-align: justify;
}
  