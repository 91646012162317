.landing-page-hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-between; */
    height: 60vh;
    min-height: 506px;
    width: 100%;
    border-radius: 50px;
    box-sizing: border-box;
    padding-top: 5%;
    padding-left: 4%;
    padding-right: 6%;
    background-color: #d1e3ef;
}

#landing-page-hero-catchphrase {
    font-family: SuisseBPIntl-Medium;
    font-size: 5.8vw;
    padding-top: 5%;
}

#landing-page-hero-desc {
    text-align: justify;
    font-family: SuisseBPIntl-Light;
    font-size: 5vw;
    padding-top: 10%;
}