#listing-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
  max-height: 100vh;
  box-sizing: border-box;
  width: 100%;
}

#listing-page-filter-section {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid gray;
  height: 40px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0.5%;
}

#listing-page-more-filters {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid gray;
  border-radius: 100px;
  width: 8%;
  height: 70%;
  line-height: 70%;
  font-family: SuisseBpIntl-Regular;
  font-size: 0.8vw;
}

#listing-page-more-filters:hover {
  cursor: pointer;
}

#listing-section {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: calc(100% - 130px);
}

.listing-page-column {
  width: 50%;
  height: 100%;
  max-height: 100%;
  box-sizing: border-box;
  padding-top: 1%;
}

#listing-section-headers {
  display: flex;
  justify-content: flex-start;
  font-size: 1.2em;
  height: 3%;
  width: 100%;
  padding-left: 1%;
  font-family: SuisseBPIntl-Antique;
}

#thumbnail-list {
  max-height: 97%;
  box-sizing: border-box;
  padding-left: 1%;
  padding-right: 1%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.thumbnail-row {
  display: flex;
  margin-top: 1%;
  margin-bottom: 2.5%;
  justify-content: space-between;
  width: 100%;
  height: 20vw;
}

#listing-page-navigation-buttons {
  display: flex;
  flex-direction: row;
  width: 50%;
  align-self: center;
  margin-bottom: 15px;
}

#listing-page-navigation-text {
  font-family: SuisseBPIntl-Medium;
  margin-bottom: 30px;
  font-size: 1.1vw;
}

#listing-map-container {
  height: 100%;
  width: 99%;
  border-radius: 25px;
  overflow: hidden;
}

#listing-page-whatsapp-icon {
  width: 70px;
  height: 70px;
  position: absolute;
  bottom: 2vh;
  right: 70px;
}

#listing-page-whatsapp-icon:hover {
  cursor: pointer;
}

#clean-filter-bar-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.85vw;
  font-family: SuisseBPIntl-Regular;
  text-decoration: underline;
  border: 1px solid gray;
  width: 8%;
  height: 70%;
  margin-left: auto;
}

#clean-filter-bar-button:hover {
  cursor: pointer;
}