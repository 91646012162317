.property-button-primary {
  background-color:#173056;
  color: white;
  font-family: "SuisseBPIntl-Bold";
  height: 54%;
  width: 41%;
}

.mobile-property-button-primary {
  background-color:#173056;
  color: white;
  font-family: "SuisseBPIntl-Bold";
  height: 70%;
  width: 41%;
  font-size: 2.4vw;
}

.property-button-secondary {
  background-color:#0F56BF;
  color: white;
  font-family: "SuisseBPIntl-Bold";
  height: 54%;
  width: 41%;
}

.mobile-property-button-secondary {
  background-color:#0F56BF;
  color: white;
  font-family: "SuisseBPIntl-Bold";
  height: 70%;
  width: 41%;
  font-size: 2.4vw;
}

.property-button-invisible {
  background-color:rgba(0,0,0,0);
  color: white;
  font-family: "SuisseBPIntl-Bold";
  height: 54%;
  width: 41%;
}