#mobile-faq-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    width: 100vw;
    overflow-y: scroll;
}
  
#mobile-faq-page > #mobile-page-title {
  font-family: SuisseBPIntl-Antique;
  font-size: 1.5em;
  margin: 5vh 0;
}
  
#mobile-faq-page > .mobile-page-header {
  width: 80%;
  font-family: SuisseBPIntl-Antique;
  font-size: 1.2;
  text-align: left;
  margin-bottom: 1%;
}
  
#mobile-faq-page > .mobile-page-divider {
  width: 80%;
  border: 1px solid gray;
  margin: 2vh 0;
}
  
#mobile-faq-page > .mobile-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4%;
  width: 80%;
  font-family: SuisseBPIntl-Regular;
  font-size: 1em;
  text-align: left;
}
  
#mobile-faq-page > .mobile-question:hover {
  cursor: pointer;
}
  
#mobile-faq-page > .mobile-answer {
  width: 80%;
  font-family: SuisseBPIntl-Thin;
  font-size: 0.9em;
  text-align: justify;
  margin: 2vh 0;
  -webkit-animation: SHOW-BOX 0.2s ease;
  -moz-animation: SHOW-BOX 0.2s ease;
  -o-animation: SHOW-BOX 0.2s ease;
  animation: SHOW-BOX 0.2s ease;
}

.mobile-faq-page-down-icon {
  box-sizing: border-box;
  width: 10%;
  height: 100%;
}
  
.mobile-faq-page-down-icon-rotated {
  height: 100%;
  width: 10%;
  transform: rotate(180deg);
}

.mobile-faq-question-internal {
  width: 80%;
}

#mobile-faq-page-last-text {
  margin-top: 10%;
  margin-bottom: 10%;
  font-family: SuisseBPIntl-Thin;
  font-size: 3.7vw;
}

#mobile-faq-page-last-text > span {
  font-family: SuisseBPIntl-Thin;
  font-weight: bold;
  text-decoration: underline;
}

#mobile-faq-page-last-text > span:hover {
  cursor: pointer;
}
  
  @-webkit-keyframes SHOW-BOX {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes SHOW-BOX {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-o-keyframes SHOW-BOX {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes SHOW-BOX {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  