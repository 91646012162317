#mobile-privacy-page {
    /* height: 100vh; */
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  #mobile-privacy-page > #mobile-page-title {
    font-family: SuisseBPIntl-Antique;
    font-size: 1.5em;
    margin: 5vh 0;
  }
  
  #mobile-privacy-page > .mobile-page-section {
    font-family: SuisseBPIntl-Medium;
    width: 85%;
    font-size: 1.25em;
    text-align: left;
    margin-bottom: 3vh;
  }
  
  #mobile-privacy-page > .mobile-page-content {
      font-family: SuisseBPIntl-Thin;
      width: 85%;
      font-size: 1em;
      text-align: justify;
      margin-bottom: 3vh;
  }
  
  #mobile-privacy-page > .mobile-page-content-header {
      font-family: SuisseBPIntl-Medium;
      font-style: italic;
      width: 85%;
      font-size: 1.25em;
      text-align: left;
      text-decoration: underline;
  }