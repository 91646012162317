#privacy-page {
  /* height: 100vh; */
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

#privacy-page > #page-title {
  font-family: SuisseBPIntl-Antique;
  font-size: 4vw;
  margin: 5vh 0;
}

#privacy-page > .page-section {
  font-family: SuisseBPIntl-Medium;
  width: 85%;
  font-size: 1.6vw;
  text-align: left;
  margin-bottom: 3vh;
}

#privacy-page > .page-content {
    font-family: SuisseBPIntl-Thin;
    width: 85%;
    font-size: 1.6vw;
    text-align: justify;
    margin-bottom: 3vh;
}

#privacy-page > .page-content-header {
    font-family: SuisseBPIntl-Medium;
    font-style: italic;
    width: 85%;
    font-size: 1.6vw;
    text-align: left;
    text-decoration: underline;
}