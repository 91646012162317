#mobile-property-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
    height: 100%;
    width: 100%;
    min-height: 844px;
    min-width: 320px;
}

#mobile-property-page-back-button-container {
    display: flex;
    align-items: center;
    font-family: SuisseBPIntl-Medium;
    font-size: 0.8em;
    width: 100%;
    height: 4.5%;
    box-sizing: border-box;
    padding: 0 4%;
}
#mobile-property-page-back-icon {
    height: 50%;
    width: auto;
    transform: rotate(90deg);
}

#mobile-property-page-back-text {
    padding-left: 1%;
    padding-top: 0.3%;
}

#mobile-property-page-carousel-container {
    box-sizing: border-box;
    padding: 0 4%;
    width: 100%;
}

.mobile-property-page-property-image {
    border-radius: 5vw;
    height: 27vh;
    object-fit: cover;
    min-height: 227px;
}

.mobile-property-page-property-modal-image {
    border-radius: 1vw;
    height: 50vw;
    object-fit: cover;
}

.mobile-floating-property-tag {
    position: relative;
    top: -47.6%;
    left: -5px;
    float: left;
    width: 90%;
    height: 100%;
    z-index: 500;
    pointer-events: none;
}

#mobile-property-page-property-info-container {
    display: flex;
    flex-direction: column;
    margin-top: 2%;
    width: 100%;
    box-sizing: border-box;
    padding: 0 4%;
}

#mobile-property-page-property-type-container {
    display: flex;
    justify-content: space-between;
    font-family: SuisseBPIntl-Antique;
    font-size: 1.1em;
}
#mobile-property-page-property-info-first-row {
    display: flex;
    width: 100%;
}

#mobile-property-page-property-features {
    display: flex;
    flex-direction: column;
    width: 60%;
    height: 100%;
    margin-top: 1%;
}

.mobile-property-page-property-feature-row {
    display: flex;
    align-items: center;
    margin-bottom: 0.8%;
}

#mobile-property-page-property-price {
    font-family: SuisseBPIntl-Antique;
    font-size: 6vw;
}

#mobile-property-page-monthly-accent {
    font-family: SuisseBPIntl-RegularItalic;
    font-size: 4vw;
    padding-left: 1%;
}

#mobile-property-page-property-municipality {
    font-family: SuisseBPIntl-Regular;
    font-size: 3.5vw;
}

#mobile-property-page-property-street {
    font-family: SuisseBPIntl-ThinItalic;
    font-size: 3vw;
}

#mobile-property-page-feature-icons-container {
    display: flex;
    align-items: flex-start;
    width: 100%;
    font-family: SuisseBPIntl-Light;
    font-size: 3vw;
    box-sizing: border-box;
    padding: 0 4%;
}

.mobile-property-page-feature-icon-container {
    display: flex;
    align-items: center;
    background-color: #E9EBEC;
    height: 30px;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 0 2%;
}

#mobile-property-page-double-bed-container {
    width: 13%;
}

#mobile-property-page-double-bed-icon {
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 45%;
    max-height: 40px;
}

#mobile-property-page-shower-container {
    width: 14%;
    margin-left: 2%;
}

#mobile-property-page-shower-icon {
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 45%;
    max-height: 40px;
}

#mobile-property-page-m2-container {
    width: 20%;
    margin-left: 2%;
}

#mobile-property-page-m2-icon {
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 100%;
    max-height: 40px;
}

#mobile-property-page-parking-container {
    width: 14%;
    margin-left: 2%;
}

#mobile-property-page-parking-icon {
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 50%;
    max-height: 40px;
}


/* .mobile-property-page-feature-icon-first {
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 22px;
    max-height: 40px;
}

.mobile-property-page-feature-icon {
    padding-left: 4%;
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 30%;
    max-height: 40px;
} */

#mobile-property-page-book-button-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 40%;
    height: 100%;
    box-sizing: border-box;
    padding-left: 2%;
    padding-bottom: 0;
}

#mobile-property-page-about-property-label {
    text-align: left;
    width: 100%;
    font-family: SuisseBPIntl-Antique;
    margin-top: 5%;
    font-size: 1em;
    box-sizing: border-box;
    padding: 0 4%;
}

#mobile-property-page-about-section {
    text-align: justify;
    margin-top: 2%;
    width: 100%;
    font-family: SuisseBPIntl-Light;
    height: auto;
    font-size: 0.9em;
    box-sizing: border-box;
    padding: 0 4%;
}

#mobile-property-page-feature-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 5%;
    margin-top: 5%;
    min-height: 50px;
    box-sizing: border-box;
    padding: 0 2%;
}

#mobile-property-page-characteristics-text {
    margin-top: 5%;
    margin-bottom: 1%;
    font-family: SuisseBPIntl-Antique;
    width: 100%;
    text-align: left;
    font-size: 1em;
    box-sizing: border-box;
    padding: 0 4%;
}

#mobile-property-page-row {
    display: flex;
    width: 100%;
    height: 22%;
    box-sizing: border-box;
    padding: 0 4%;
}

#mobile-property-page-amenities-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    margin-top: 4%;
}

.mobile-property-page-amenity-column {
    width: 30%;
}

.mobile-property-page-amenity {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: SuisseBPIntl-Light;
    font-size: 0.8em;
    margin-bottom: 10%;
}

.mobile-property-page-amenity-icon {
    width: 5vw;
    margin-right: 10px;
}

.mobile-property-map-container {
    margin-top: 1%;
    margin-bottom: 1%;
    height: 43%;
    min-height: 180px;
    width: 100%;
    border-radius: 25px;
    overflow: hidden;
    box-sizing: border-box;
    padding: 0 4%;
}