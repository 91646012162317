#more-filters-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80%;
  width: 55%;
  position: fixed;
  top: 53.3%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: white;
  border: 1px solid gray;
  border-radius: 20px;
  overflow-y: hidden;
}

#mobile-more-filters-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 97%;
  width: 98%;
  position: fixed;
  top: 51%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: white;
  border: 1px solid gray;
  border-radius: 20px;
  overflow-y: scroll;
}

#more-filters-modal-title-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid black;
  font-family: SuisseBPIntl-Medium;
  padding: 0% 2%;
  box-sizing: border-box;
}

#more-filters-modal-filter-container {
  overflow-y: scroll;
}

.more-filters-modal-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 95%;
  border-bottom: 1px solid lightgray;
  box-sizing: border-box;
  padding-top: 2%;
  padding-bottom: 0.5%;
}

.more-filters-modal-section-last {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 95%;
  box-sizing: border-box;
  padding-top: 2%;
  padding-bottom: 0.5%;
}

.more-filters-modal-section-title {
  font-size: 1.2vw;
  padding-bottom: 0.5%;
  font-family: SuisseBPIntl-Medium;
}


.more-filters-modal-text-input {
  text-align: center;
  width: 8vw;
  height: 4.5vh;
  border: 1px solid darkgray;
  border-radius: 10px;
  font-size: 1vw;
  font-family: SuisseBPIntl-Light;
}

.mobile-more-filters-modal-text-input {
  text-align: center;
  width: 30vw;
  height: 4.5vh;
  border: 1px solid darkgray;
  border-radius: 10px;
  font-size: 3vw;
  font-family: SuisseBPIntl-Light;
}

.more-filters-modal-text-input::-webkit-input-placeholder {
  font-size: 1vw;
  font-family: SuisseBPIntl-Light;
}

.more-filters-modal-numeric-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 45%;
  font-size: 2.6vw;
  margin-bottom: 10px;
}

.mobile-more-filters-modal-numeric-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 98%;
  font-size: 3vw;
  margin-bottom: 10px;
}

.more-filters-modal-clear-button {
  font-family: SuisseBPIntl-light;
  text-decoration: underline;
  font-size: 1vw;
}

.more-filters-modal-clear-button:hover {
  cursor: pointer;
}

.more-filters-modal-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

#more-filters-modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  min-height: 10%;
  border-top: 1px solid lightgray;
}

#more-filters-modal-close:hover {
  cursor: pointer;
}
