body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "SuisseBPIntl-Light";
  src: local("SuisseBPIntl-Light"),
    url("./fonts/SuisseBPIntl-Light.otf") format("truetype");
}

@font-face {
  font-family: "SuisseBPIntl-Medium";
  src: local("SuisseBPIntl-Medium"),
    url("./fonts/SuisseBPIntl-Medium.otf") format("truetype");
}

@font-face {
  font-family: "SuisseBPIntl-Bold";
  src: local("SuisseBPIntl-Bold"),
    url("./fonts/SuisseBPIntl-Bold.otf") format("truetype");
}

@font-face {
  font-family: "SuisseBPIntl-Thin";
  src: local("SuisseBPIntl-Thin"),
    url("./fonts/SuisseBPIntl-Thin.otf") format("truetype");
}

@font-face {
  font-family: "SuisseBPIntl-Antique";
  src: local("SuisseBPIntl-Antique"),
    url("./fonts/SuisseBPIntl-Antique.otf") format("truetype");
}

@font-face {
  font-family: "SuisseBPIntl-RegularItalic";
  src: local("SuisseBPIntl-RegularItalic"),
    url("./fonts/SuisseBPIntl-RegularItalic.otf") format("truetype");
}

@font-face {
  font-family: "SuisseBPIntl-Regular";
  src: local("SuisseBPIntl-Regular"),
    url("./fonts/SuisseBPIntl-Regular.otf") format("truetype");
}

@font-face {
  font-family: "SuisseBPIntl-ThinItalic";
  src: local("SuisseBPIntl-ThinItalic"),
    url("./fonts/SuisseBPIntl-ThinItalic.otf") format("truetype");
}
