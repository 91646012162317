#property-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100vh;
  box-sizing: border-box;
  width: 100%;
  overflow-y: scroll;
}

#property-page-container {
  display: flex;
  width: 100%;
  overflow-y: scroll;
  height: calc(100% - 90px);
}

.property-column {
  width: 50%;
  height: 100%;
  text-align: left;
  box-sizing: border-box;
  padding-left: 1%;
  padding-right: 1%;
  padding-top: 0.5%;
}

#property-page-back-button-container {
  display: flex;
  align-items: center;
  font-family: SuisseBPIntl-Medium;
  font-size: 1.2vw;
  width: 100%;
  height: 4.8vh;
}

#property-page-back-button-container:hover {
  cursor: pointer;
}

#property-page-back-icon {
  height: 50%;
  width: auto;
  transform: rotate(90deg);
}

#property-page-back-text {
  padding-left: 1%;
  padding-top: 0.3%;
}

.property-page-property-image {
  border-radius: 1vw;
  height: 42vh;
  object-fit: cover;
}

.property-page-property-modal-image {
  border-radius: 1vw;
  height: 85vh;
  object-fit: cover;
}

.floating-property-tag {
  position: absolute;
  float: left;
  left: 0.1vw;
  width: 23.5vw;
  height: 27vw;
  z-index: 500;
  pointer-events: none;
}

#property-page-characteristics-text {
  margin-top: 3%;
  margin-bottom: 1%;
  font-family: SuisseBPIntl-Antique;
  font-size: 1.3vw;
}

#property-page-amenities-container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 35%;
}

.property-page-amenity-column {
  width: 18vw;
}

.property-page-amenity {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0;
  font-family: SuisseBPIntl-Light;
  font-size: 1.2vw;
}

.property-page-amenity-icon {
  width: 2vw;
  margin-right: 10px;
}

#property-page-property-type-container {
  display: flex;
  justify-content: space-between;
  font-family: SuisseBPIntl-Antique;
  font-size: 1.2vw;
}

#property-page-property-info-container {
  display: flex;
  flex-direction: column;
  margin-top: 5.5%;
  width: 100%;
  height: 48%;
}

#property-page-property-info-first-row {
  display: flex;
  width: 100%;
  height: 40%;
}

#property-page-property-features {
  display: flex;
  flex-direction: column;
  width: 60%;
  height: 100%;
  margin-top: 1%;
}

.property-page-property-feature-row {
  display: flex;
  align-items: center;
  margin-bottom: 0.8%;
}

#property-page-property-price {
  font-family: SuisseBPIntl-Antique;
  font-size: 2.6vw;
}

#property-page-monthly-accent {
  font-family: SuisseBPIntl-RegularItalic;
  font-size: 1.4vw;
  padding-left: 1%;
}

#property-page-property-municipality {
  font-family: SuisseBPIntl-Regular;
  font-size: 1.4vw;
}

#property-page-property-street {
  font-family: SuisseBPIntl-ThinItalic;
  font-size: 1.2vw;
}

#property-page-book-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
  height: 100%;
}

#property-page-feature-icon-container {
  display: flex;
  align-items: center;
  height: 20%;
  width: 100%;
  font-family: SuisseBPIntl-Light;
  font-size: 1.2vw;
  box-sizing: border-box;
  margin-top: 1%;
}

.property-page-detail-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  border-radius: 10px;
  width: 20%;
  background-color: #E9EBEC;
  box-sizing: border-box;
  padding: 0 2%;
}

#property-page-double-bed-container {
  width: 16%;
}

#property-page-double-bed-icon {
  display: block;
  box-sizing: border-box;
  height: auto;
  width: 45%;
  max-height: 40px;
}

#property-page-shower-container {
  width: 16%;
  margin-left: 2%;
}

#property-page-shower-icon {
  display: block;
  box-sizing: border-box;
  height: auto;
  width: 50%;
  max-height: 40px;
}

#property-page-m2-container {
  width: 25%;
  margin-left: 2%;
}

#property-page-m2-icon {
  display: block;
  box-sizing: border-box;
  height: auto;
  width: 100%;
  max-height: 40px;
}

#property-page-parking-container {
  width: 16%;
  margin-left: 2%;
}

#property-page-parking-icon {
  display: block;
  box-sizing: border-box;
  height: auto;
  width: 50%;
  max-height: 40px;
}

#property-page-about-property-label {
  font-family: SuisseBPIntl-Antique;
  font-size: 1.2vw;
}

#property-page-about-section {
  margin-top: 1%;
  width: 95%;
  height: 30%;
  text-align: justify;
  font-family: SuisseBPIntl-Thin;
  font-size: 1.2vw;
}

#property-page-feature-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 20%;
}

.property-map-container {
  margin-top: 1vw;
  height: 43%;
  border-radius: 25px;
  overflow: hidden;
}

#property-page-whatsapp-icon {
  width: 70px;
  height: 70px;
  position: absolute;
  bottom: 2vh;
  right: 70px;
}